<p>Effective Date: [12/19/2023]</p>

<ol>
  <li>
    <h2>INTRODUCTION</h2>

    <p>Welcome to GETHINS LIMITED (“Gethins”, “Company”, “we”, “our”, “us”)</p>
    <p>
      These Terms of Use govern the usage of the website
      <a [href]="environment.policy.url" target="_blank">
        {{ environment.policy.url }}</a
      >
      and establish the terms and conditions for the purchase of products and
      services offered by Gethins on {{ environment.policy.site }} (the
      “Website”).
    </p>
    <p>
      {{ environment.policy.name }} is also accessible through a mobile
      application, and therefore, these terms are applicable to users of the
      application as well.
    </p>
    <p>
      Your agreement with us includes these Terms and our
      <a [href]="environment.policy.docsBaseUrl + 'privacy/'" target="_blank">
        Privacy Policy</a
      >
      (“Agreements”). You acknowledge that you have read and understood the
      Agreements and agree to be bound of them.
    </p>
    <p>
      Your access to and use of the Service is conditional upon your acceptance
      of and compliance with these Terms. These Terms apply to all visitors and
      users who wish to access or use the Service.
    </p>
    <p>
      When you sign up for any of the Services or otherwise use or access them,
      you agree to be bound by these Terms and all applicable laws, rules, and
      regulations. By using the Service, you indicate that you accept these
      Terms and that you agree to abide by them.
    </p>
    <p>
      If you do not agree with or you cannot comply with these Terms, please
      refrain from using the Service and navigate away from the Website.
    </p>
  </li>

  <li>
    <h2>DEFINITIONS</h2>

    <ol>
      <li>
        <p>
          Company is the operator of
          <a [href]="environment.policy.url" target="_blank">
            {{ environment.policy.url }}</a
          >. When first-person pronouns are used in these Terms these provisions
          are referring to the Company. Additionally, when the term the
          “Website” is used, this term refers to
          <a [href]="environment.policy.url" target="_blank">
            {{ environment.policy.url }}</a
          >, any predecessor or successor domain or URL as well as the
          application.
        </p>
      </li>
      <li>
        <p>
          <b>Content</b> means all images, content, text, software, graphics,
          data, messages, or any other information, and any other Website
          content.
        </p>
      </li>
      <li>
        <p>
          <b>User</b> is defined in our
          <a
            [href]="environment.policy.docsBaseUrl + 'privacy/'"
            target="_blank"
          >
            Privacy Policy
          </a>
        </p>
      </li>
      <li>
        <p>
          <b>You, the User</b> – as the User of this Website and/or Services,
          these Terms will refer to the User as “You” or “you” or through any
          second-person pronouns, such as “Yours,” etc. Hereinafter, the User of
          the Website and/or Services shall be referred to in applicable
          second-person pronouns. You must be over eighteen (18) years of age,
          or you have attained the age of majority in Your respective
          jurisdiction. You should not use this Website if it is not permitted
          by any law of Your respective jurisdiction.
        </p>
      </li>
      <li>
        <p><b>Service</b> means the use of the Website.</p>
      </li>
    </ol>
  </li>

  <li>
    <h2>ELECTRONIC SIGNATURES /AGENT REQUIRED</h2>

    <ol>
      <li>
        <p>
          Nobody is authorized to access the Website(s) or accepted as a User
          unless they have signed the Agreements. Such signature does not need
          to be a physical signature, since electronic acceptance of these is
          permitted by the Electronic Signatures in Global and National Commerce
          Act (E-Sign Act) and other similar state, national, international and
          country laws. You manifest Your agreement to this contractual
          Agreement by taking any act demonstrating Your assent thereto. Most
          likely, You have clicked or will click a button containing the words
          “I agree”, “Start” or some similar syntax or go through the
          registration process. You should understand that this has the same
          legal effect as You placing Your physical signature on any other legal
          contract. If You click any link, button, or other device provided to
          You in any part of Our Website(s)’s interface, then you have legally
          agreed to all of the terms and conditions of these Terms.
          Additionally, by using any of Our Website(s)’s features or Services in
          any manner, You understand and agree that We will consider such use as
          Your affirmation of Your complete and unconditional acceptance to all
          of the terms in these Terms
        </p>
      </li>
      <li>
        <p>
          If You fail to sign this Agreement, You understand that You are an
          unauthorized user of the Website and Services, despite any payments
          made or subscriptions sold to You. No act or omission by Us should be
          interpreted as a waiver of the requirement that You assent to this
          Agreement. If You fail to do so, You are still bound by the terms of
          this Agreement by virtue of Your viewing the Website or using any
          portion of the Website or Our Services.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>ELECTRONIC SIGNATURES /AGENT REQUIRED</h2>

    <ol>
      <li>
        <p>
          {{ environment.policy.site }} offers a program that allows You to chat
          with a chatbot via text and photos. The chatbot is designed as a
          software assistant that responds to your messages.
        </p>
      </li>
      <li>
        <p>
          By using the Service, You acknowledge that you are communicating with
          software whose activity we cannot constantly control. You agree that
          our responsibility for the activities of the software is limited in
          accordance with these Terms. We are responsible only for violation of
          user rights and the failure to perform our responsibilities under
          these Terms. If you choose to act on the Service’s advice, you assume
          the risk of the consequences of such actions.
        </p>
      </li>
      <li>
        <p>
          If you find the Service’s responses are offensive, you can report it
          via email at
          <a [href]="'mailto:' + environment.policy.email">{{
            environment.policy.email
          }}</a
          >.
        </p>
      </li>
      <li>
        <p>
          {{ environment.policy.site }} is not a provider of healthcare or
          medical services, nor does it offer medical care, mental health
          services, or other professional services. Only your doctor, therapist,
          or any other specialist can provide such services.
          {{ environment.policy.site }} MAKES NO CLAIMS, REPRESENTATIONS,
          WARRANTIES, OR GUARANTEES THAT THE SERVICE PROVIDES A THERAPEUTIC,
          MEDICAL, OR OTHER PROFESSIONAL HELP.
        </p>
      </li>
      <li>
        <p>
          Please refrain from using our Service for emergency situations. In
          case of a medical or mental health emergency,
          <i>we strongly advise</i>
          calling an ambulance, or consulting a doctor or therapist. We are not
          liable for any mental disorders, tendencies towards suicide, or any
          other health-related damages.
        </p>
      </li>
      <li>
        <p>
          You understand that you are communicating with software, and it sends
          you messages based on the triggers you provide. The content sent to
          you by AI is user-generated and processed by the Service in the manner
          and under the terms stipulated in these Terms. By using this Service,
          You may be exposed to content that You may find offensive, indecent,
          incorrect, or objectionable, and You agree that, under no
          circumstances, will the Company be liable in any way for any content,
          including any errors or omissions in any content, or any loss or
          damage of any kind incurred as a result of your use of any content.
        </p>
      </li>
      <li>
        <p>
          We reserve the right, at our sole discretion, to determine whether any
          content is appropriate and complies with these Terms and any
          applicable laws. If we conclude, at our sole discretion, that a
          Content is or seems to be inappropriate, and we may reject or remove
          such content. We also reserve the right to make formatting and edits
          and change the manner of any content. Additionally, we may limit or
          revoke the use of the Service if You post such objectionable content.
          As we cannot control all content posted by users and/or third parties
          on the Service, you agree to use the Service at your own risk.
        </p>
      </li>
      <li>
        <p>
          Except in jurisdictions where such provisions are restricted, in no
          event shall we, nor our directors, employees, be liable for any
          indirect, incidental, special, consequential, or punitive damages,
          including without limitation, loss of profit, data, use, goodwill, or
          other intangible losses, cost of procurement of substitute services or
          service interruptions, resulting from (i) your access to or use of, or
          inability to access or use the Service; (ii) any conduct or content of
          any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use or alteration of your
          transmissions or Content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory. Notwithstanding
          anything to the contrary contained herein, our liability to you for
          any cause whatsoever, and regardless of the form of the action, will
          at all times be limited to the amount paid, if any, by you to us for
          the Service during the term of use of your account.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>PURCHASES</h2>

    <ol>
      <li>
        <p>
          If you wish to purchase any product or service made available through
          Service (the “Purchase”), you may be asked to supply certain
          information relevant to your Purchase including but not limited to,
          your credit or debit card number, the expiration date of your card,
          your billing address, and your shipping information.
        </p>
      </li>
      <li>
        <p>
          You represent and warrant that: (i) you have the legal right to use
          any card(s) or other payment method(s) in connection with any
          Purchase; and that (ii) the information you supply to us is true,
          correct and complete.
        </p>
      </li>
      <li>
        <p>
          We may employ the use of third party services for the purpose of
          facilitating payment and the completion of Purchases. By submitting
          your information for a Purchase, you grant us the right to provide the
          information to these third-party payment processors, in accordance
          with our Privacy Policy.
        </p>
      </li>
      <li>
        <p>
          We reserve the right to refuse or cancel your order at any time for
          reasons including but not limited to: product or service
          unavailability, errors in the product or service description or
          pricing, inaccuracies in your order or other legitimate reasons.
        </p>
      </li>
      <li>
        <p>
          We reserve the right to refuse or cancel your order if fraud or an
          unauthorized or illegal transaction is suspected.
        </p>
      </li>
    </ol>
  </li>

  @if (environment.buildVersion !== buildVersion.legal) {
    <li>
      <h2>AGE AND LEGAL RIGHT OF ACCESS</h2>

      <p>
        Through subscription or the downloading of material on this website, you
        warrant and confirm that:
      </p>

      <ol>
        <li>
          <p>
            you are at least 18 years of age or the age of majority in your
            jurisdiction;
          </p>
        </li>
        <li>
          <p>
            you are familiar with and know the local, federal and international
            laws in your jurisdiction that affects the legal right to access
            adult-oriented materials;
          </p>
        </li>
        <li>
          <p>
            you have the legal right to access adult-oriented materials and
            {{ environment.policy.site }} has the legal right to transmit such
            content;
          </p>
        </li>
        <li>
          <p>
            you are accessing adult-oriented materials for your own private
            enjoyment and will not make these materials available to any person
            below the age of 18 or the age of majority in their respective
            jurisdiction.
          </p>
        </li>
      </ol>
    </li>

    <li>
      <h2>18 U.S.C. 2257 DECLARATION OF CONFORMITY</h2>

      <p>
        All models, actors, actresses and other persons that appear in any
        visual depiction of actual or simulated sexually explicit conduct in our
        Website are at least eighteen (18) years old when the visual depictions
        were created. Pursuant to Section 2257 of Title 18, United States Code,
        records verifying the age of all models, artists, actors, actresses and
        other persons that appear in sexually explicit conduct real or simulated
        on this Website are stored and kept in the records of the Company. For
        additional details, you may refer to our 18 U.S.C. 2257 compliance
        statement.
      </p>
    </li>
  }

  <li>
    <h2>CONTENT</h2>

    <ol>
      <li>
        <p>
          Our Website provides Users solely with entertainment and recreational
          services. {{ environment.policy.site }} provides the content to its
          Users exclusively for their own personal and non-commercial use. Users
          of the Service are only permitted to use their downloaded copy for
          their own personal purposes, private and non-commercial content. We
          reserve the right to terminate your rights to use the Content if you
          do not comply with the terms of the Agreements or our copyright is
          violated.
        </p>
      </li>
      @if (environment.buildVersion !== buildVersion.legal) {
        <li>
          <p>
            You acknowledge and understand that some or all of the Content and
            materials featured and/or displayed on the Website may depict
            activity that is restricted to adults and may therefore be
            inappropriate for viewing by minors. You acknowledge that You are
            aware of the nature of the content and materials provided by the
            Website and that You are not offended by such content and materials,
            and that You access the Website and Services freely, voluntarily,
            willingly, and for Your own personal enjoyment.
          </p>
        </li>
      }
      <li>
        <p>
          This Website does not contain any forbidden Content including real
          violence, pedophilia, zoophile, incest, people intoxicated by drugs,
          people asleep, weapons or anything else prohibited by national law. If
          you notice any inappropriate Content and suspect it depicts
          individuals who are underage, please report it to
          <a [href]="'mailto:' + environment.policy.email">{{
            environment.policy.email
          }}</a>
          and we will process that report immediately.
        </p>
      </li>
      <li>
        <p>
          GETHINS has the right but not the obligation to monitor and edit all
          Content provided by Users.
        </p>
      </li>
      <li>
        <p>
          Content found on or through this Service is the property of GETHINS
          LIMITED or used with permission. You may not distribute, modify,
          transmit, reuse, download, repost, copy, or use said Content, whether
          in whole or in part, for commercial purposes or for personal gain,
          without express advance written permission from us.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>LICENSE AND INTELLECTUAL PROPERTY</h2>

    <ol>
      <li>
        <p>
          Subject to Your acceptance of this Agreement, We grant You a limited,
          non-exclusive, non-transferable personal license to access and use the
          Website and the Services contained therein. We provide the Services on
          this Website for a personal, non-commercial use by Users of the
          Website. Users of this Website are granted a single copy license to
          view content of the Website.
        </p>
      </li>
      <li>
        <p>
          The Content on the Website including text, graphical images,
          photographs, music, video, software, scripts and trademarks, service
          marks and logos contained therein (the “<b>Proprietary Materials</b
          >”), are owned by and licensed to the Company. All Proprietary
          Materials are subject to copyright, trademark and/or other rights
          under the laws of applicable jurisdictions, including domestic laws,
          foreign laws, and international conventions. We reserve all our rights
          over our Proprietary Materials.
        </p>
      </li>
      <li>
        <p>
          Except as otherwise explicitly permitted, you agree not to copy,
          modify, publish, transmit, distribute, participate in the transfer or
          sale of, create derivative works of, or in any other way exploit, in
          whole or in part, any Content and Proprietary Materials.
        </p>
      </li>
      <li>
        <p>
          All content published on our different websites specifically,
          including but not limited to videos, cinematographic works, designs,
          text, graphics, logos, icons, buttons, software, trade names, text,
          the Website itself, trademarks, industrial or any other signs of
          industrial and commercial use are subject to the intellectual property
          rights of Gethins or third parties who have been authorized by the
          legal owners of this Website. Non-compliance with this section will be
          considered a serious violation, leading to legal action pursued
          through both civil and criminal laws.
        </p>
      </li>
      <li>
        <p>
          Any act by Users of the Services or Content that exploits or
          commercially uses, directly or indirectly, in whole or in part, any
          content, images, forms, indexes, and other formal expressions that
          form part of our Websites is strictly prohibited without the prior
          written permission of Gethins. Gethins reserves the right to limit
          access to its websites, products, and/or services offered, as well as
          the subsequent publication of opinions, observations, images, or
          comments that users may send via email or forums unless they
          explicitly express otherwise in a clear and unmistakable manner.
        </p>
      </li>
      <li>
        <p>
          In this regard, Gethins may establish, if deemed necessary and without
          prejudice to user responsibility, the filters required to prevent
          opinions considered racist, xenophobic, discriminatory, defamatory, or
          those that could generate violence or propagate clearly illicit or
          harmful content.
        </p>
      </li>
      <li>
        <p>
          It is forbidden for any third party to use, directly or indirectly,
          with or without profit, any technical, logical, or technological
          resources to benefit from each and every one of the contents, forms,
          indexes, and other formal expressions that are part of our websites or
          created by Gethins. Specifically, any link, hyperlink, framing, or
          similar that may be established on any of our websites without the
          prior written consent of Gethins is prohibited. Any violation of this
          section shall be considered a violation of the legitimate intellectual
          property rights of Gethins on websites and all their contents.
        </p>
      </li>
      <li>
        <p>
          By posting Content on or through Service, You represent and warrant
          that: (i) Content is yours (you own it) and/or you have the right to
          use it and the right to grant us the rights and license as provided in
          these Terms, and (ii) that the posting of your Content on or through
          Service does not violate the privacy rights, publicity rights,
          copyrights, contract rights or any other rights of any person or
          entity. We reserve the right to terminate the account of anyone found
          to be infringing on a copyright.
        </p>
      </li>
      <li>
        <p>
          You retain any and all of your rights to any Content you submit, post
          or display on or through Service and you are responsible for
          protecting those rights. We take no responsibility and assume no
          liability for Content you or any third-party posts on or through
          Service. However, by posting Content using Service you grant us the
          right and license to use, modify, publicly perform, publicly display,
          reproduce, and distribute such Content on and through Service. You
          agree that this license includes the right for us to make your Content
          available to other users of Service, who may also use your Content
          subject to these Terms.
        </p>
      </li>
      <li>
        <p>
          Any violation of this Section may result in the immediate removal of
          such content and may lead to the suspension or termination of your
          account. We reserve the right to take appropriate legal action if the
          uploaded content violates applicable laws and regulations.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>COPYRIGHT</h2>

    <ol>
      <li>
        <p>
          The contents, materials, images, designs, videos, and any other
          content included on this website are protected by national and
          international copyright laws and cannot be reproduced, transmitted,
          copied, edited, or published in any way without the written permission
          of Gethins. Unauthorized reproduction, distribution, or use of our
          content is strictly prohibited. Any violation, without exception, will
          be pursued and prosecuted to the fullest extent of copyright, data
          protection, and intellectual property laws.
        </p>
      </li>
      <li>
        <p>
          We respect to the intellectual property rights of others. It is our
          policy to respond to any claim that the Content posted on the Service
          infringes on the copyright or other intellectual property rights
          (“Infringement”) of any person or entity.
        </p>
      </li>
      <li>
        <p>
          If you are a copyright owner, or authorized on behalf of one, and you
          believe that the copyrighted work has been copied in a way that
          constitutes copyright infringement, please submit your claim via email
          to
          <a [href]="'mailto:' + environment.policy.email">{{
            environment.policy.email
          }}</a
          >, with the subject line: “Copyright Infringement” and include in your
          claim a detailed description of the alleged Infringement as detailed
          in the below section, “DMCA Notice and Procedure for Copyright
          Infringement Claims”
        </p>
      </li>
      <li>
        <p>
          You may be held accountable for damages (including costs and
          attorneys’ fees) for misrepresentation or bad faith claims of the
          infringement of any Content found on and/or the through the Service on
          your copyright.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>DMCA Notice and Procedure for Copyright Infringement Claims</h2>

    <p>
      You may submit a notification pursuant to the Digital Millennium Copyright
      Act (DMCA) by providing our Copyright Agent with the following information
      in writing (see 17 U.S.C 512(c)(3) for further detail):
    </p>

    <ol>
      <li>
        <p>
          an electronic or physical signature of the person authorized to act on
          behalf of the owner of the copyright’s interest;
        </p>
      </li>
      <li>
        <p>
          a clear description of the copyrighted work that you claim has been
          infringed, including the URL (i.e., web page address) of the location
          where the copyrighted work exists or a copy of the copyrighted work or
          any other relevant details that can assist in identifying the
          allegedly infringing material;
        </p>
      </li>
      <li>
        <p>
          identification of the URL or other specific location on Service where
          the material that you claim is infringing is located;
        </p>
      </li>
      <li><p>your address, telephone number, and email address;</p></li>
      <li>
        <p>
          a statement by you that you have a good faith belief that the disputed
          use is not authorized by the copyright owner, its agent, or the law;
        </p>
      </li>
      <li>
        <p>
          a statement by you, made under penalty of perjury, that the above
          information in your notice is accurate and that you are the copyright
          owner or authorized to act on the copyright owner’s behalf.
        </p>
      </li>

      <p>
        You can contact our Copyright Agent via email at
        <a [href]="'mailto:' + environment.policy.email">{{
          environment.policy.email
        }}</a
        >.
      </p>
    </ol>
  </li>

  <li>
    <h2>ACCOUNTS AND PASSWORDS</h2>

    <ol>
      <li>
        <p>
          When you create an account with us, you guarantee that you are above
          the age of 18 or the age of majority in your jurisdiction, where
          required. Additionally, you gurantee that the information you provide
          us with is accurate, complete, and current at all times. Inaccurate,
          incomplete, or obsolete information may result in the immediate
          termination of your account on the Service.
        </p>
      </li>
      <li>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You agree to accept
          responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our
          Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your
          account.
        </p>
      </li>
      <li>
        <p>
          You may not use as a username the name of another person or entity or
          a name that is not lawfully available for use, a name or trademark
          that is subject to any rights of another person or entity other than
          you, without appropriate authorization. You may not use as a username
          any name that is offensive, vulgar, or obscene.
        </p>
      </li>
      <li>
        <p>
          As a subscriber or user via pay per download of this Website, you are
          granted a username and password that provides access to Content. You
          agree not to share or publish this information to another website or
          in any way provide access to your username and/or password to any
          other person or organization other than yourself. Shared passwords, if
          detected, will be deactivated immediately. Any abuse of passwords
          and/or user credentials will be reported to our processing
          enterprises, preventing further credit card use.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>SERVICE’S FUNCTIONALITY AND ACCURACY</h2>

    <ol>
      <li>
        <p>
          Gethins disclaims any responsibility in the case of interruptions or
          malfunctions of services or content offered on this Website,
          regardless of the cause. Gethins will not be responsible for network
          or internet connection failures, temporary power supply issues, or any
          other type of indirect damage that can be caused to Users through no
          fault of Gethins.
        </p>
      </li>
      <li>
        <p>
          The Content included or available on our Website may contain
          inaccuracies or typographical errors. We may periodically make changes
          to this information. Gethins may introduce improvements and/or changes
          to our Services or Content at any time. While we have obtained
          information and materials from sources considered reliable, and taken
          appropriate steps to ensure correctness, we do not guarantee accuracy
          and updating. Furthermore, the contents of our Website are for
          informational purposes regarding quality, situation, hosting,
          services, and prices.
        </p>
      </li>
      <li>
        <p>
          You may provide us either directly at
          <a [href]="'mailto:' + environment.policy.email">{{
            environment.policy.email
          }}</a>
          or via third-party tools with information and feedback concerning
          errors, suggestions for improvements, ideas, problems, complaints, and
          other matters related to our Service (the “Feedback”). You acknowledge
          and agree that: (i) you shall not retain, acquire or assert any
          intellectual property right or other right, title or interest in or to
          the Feedback; (ii) Company may have development ideas similar to the
          Feedback; (iii) Feedback does not contain confidential information or
          proprietary information from you or any third party; and (iv) Company
          is not under any obligation of confidentiality with respect to the
          Feedback. In the event the transfer of the ownership to the Feedback
          is not possible due to applicable mandatory laws, you grant Company
          and its affiliates an exclusive, transferable, irrevocable,
          free-of-charge, sub-licensable, unlimited and perpetual right to use
          (including copy, modify, create derivative works, publish, distribute
          and commercialize) Feedback in any manner and for any purpose.
        </p>
      </li>
      <li>
        <p>We assume no liability in respect of errors and inaccuracies.</p>
      </li>
    </ol>
  </li>

  <li>
    <h2>SOCIAL MEDIA</h2>

    <ol>
      <li>
        <p>
          {{ environment.policy.name }}, as legal domain, has or may have or
          acquire open profiles on various social networks. In all these
          networks, we will be responsible for processing the data of the Users,
          and/or followers who have voluntarily included themselves in our
          company profile. The treatment of such data by Gethins will adhere to
          the policies and guidelines set forth by each respective social
          network.
        </p>
      </li>
      <li>
        <p>
          We may inform our followers, through any means permitted by the
          network, about our activities, new products, events and personalized
          offers. We do not extract any data from our social networks, unless we
          have the explicitly consent from the User for such purposes.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>EXEMPTION OF RESPONSIBILITY FOR ANY USER BEHAVIORS</h2>

    <ol>
      <li>
        <p>
          We assume no responsibility for any consequences arising from the
          behaviors and performances mentioned previously. Additionally, we do
          not accept any responsibility for the content, services, products,
          etc., provided by third parties, which may be accessed directly or
          through banners, links, hyperlinks, framing, or similar links from the
          Website.
        </p>
      </li>
      <li>
        <p>
          In line with the information stated in the preceding paragraph,
          Gethins is authorized to modify or alter such observations, opinions,
          or comments to adapt them to the editorial format needs of our
          Website. These modifications or alterations do not imply any
          infringement on the moral rights that users may hold over them
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>LINKS TO OTHER WEBSITES</h2>

    <ol>
      <li>
        <p>
          Our Service may contain links to third party websites or services that
          are not owned or controlled by GETHINS.
        </p>
      </li>
      <li>
        <p>
          GETHINS has no control over, and assumes no responsibility for the
          content, privacy policies, or practices of any third-party websites or
          services. We do not warrant the offerings of any of these
          entities/individuals or their websites.
        </p>
      </li>
      <li>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT GETHINS SHALL NOT BE RESPONSIBLE OR
          LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR
          ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON
          ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH
          THIRD PARTY WEB SITES OR SERVICES.
        </p>
      </li>
      <li>
        <p>
          WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
          POLICIES OF ANY THIRD-PARTY WEBSITES OR SERVICES THAT YOU VISIT.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>DISCLAIMER OF WARRANTY</h2>

    <ol>
      <li>
        <p>
          THESE SERVICES ARE PROVIDED BY GETHINS ON AN “AS IS” AND “AS
          AVAILABLE” BASIS. GETHINS MAKES NO REPRESENTATIONS OR WARRANTIES OF
          ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES,
          OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU
          EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND
          ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
        </p>
      </li>
      <li>
        <p>
          NEITHER GETHINS NOR ANY PERSON ASSOCIATED WITH GETHINS MAKES ANY
          WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES.
          WITHOUT LIMITING THE FOREGOING, NEITHER GETHINS NOR ANYONE ASSOCIATED
          WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT,
          OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE
          ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
          CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE
          FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR
          ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE
          MEET YOUR NEEDS OR EXPECTATIONS.
        </p>
      </li>
      <li>
        <p>
          GETHINS HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
          OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT TO ANY
          WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
          PARTICULAR PURPOSE.
        </p>
      </li>
      <li>
        <p>
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED
          OR LIMITED UNDER APPLICABLE LAW.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>LIMITATION OF LIABILITY</h2>

    <ol>
      <li>
        <p>
          EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
          DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE,
          SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES
          (INCLUDING ATTORNEYS’ FEES AND ALL RELATED COSTS AND EXPENSES OF
          LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER
          OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION
          OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF
          OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY
          CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS
          AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL
          LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY HAS BEEN
          PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS
          PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY,
          IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
          SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR
          PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION
          OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR
          LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>LANGUAGE</h2>

    <p>
      The primary legal language established on our websites is English.
      Therefore, we request that communication with us be conducted primarily in
      English.
    </p>
  </li>

  <li>
    <h2>NO AGENCY RELATIONSHIP</h2>

    <p>
      Nothing in this Agreement shall be deemed to constitute, create, imply,
      give effect to, or otherwise recognize a partnership, employment, joint
      venture, or formal business entity of any kind; and the rights and
      obligations of the parties shall be limited to those expressly set forth
      herein.
    </p>
  </li>

  <li>
    <h2>TERMINATION</h2>

    <p>
      We may terminate or suspend your account and bar access to the Service
      immediately, without prior notice or liability, under our sole discretion,
      for any reason whatsoever and without limitation, including but not
      limited to a breach of Terms and/or Agreements.
    </p>
    <p>
      If you wish to terminate your account, you may simply discontinue using
      the Service.
    </p>
    <p>
      All provisions of the Terms which by their nature should survive
      termination shall survive termination, including, without limitation,
      ownership provisions, warranty disclaimers, indemnity and limitations of
      liability.
    </p>
  </li>

  <li>
    <h2>GOVERNING LAW</h2>

    <p>
      These Terms shall be governed and construed in accordance with the laws of
      Cyprus, which governing law applies to agreement without regard to its
      conflict of law provisions.<br />Our failure to enforce any right or
      provision of these Terms will not be considered a waiver of those rights.
      If any provision of these Terms is held to be invalid or unenforceable by
      a court, the remaining provisions of these Terms will remain in effect.
      These Terms constitute the entire agreement between us regarding our
      Service and supersede and replace any prior agreements we might have had
      between us regarding the Service.
    </p>
  </li>

  <li>
    <h2>CHANGES TO SERVICE</h2>

    <p>
      We reserve the right to withdraw or amend our Service, and any service or
      material we provide via the Service, in our sole discretion without
      notice. We will not be liable if for any reason all or any part of the
      Service is unavailable at any time or for any period. From time to time,
      we may restrict access to some parts of the Service, or the entire
      Service, to users, including registered users.
    </p>
  </li>

  <li>
    <h2>AMENDMENTS TO TERMS</h2>

    <p>
      We may amend Terms at any time by posting the amended terms on this site.
      It is your responsibility to review these Terms periodically.
    </p>
    <p>
      Your continued use of the Platform following the posting of revised Terms
      means that you accept and agree to the changes. You are expected to check
      this page frequently, so you are aware of any changes, as they are binding
      on you.
    </p>
    <p>
      By continuing to access or use our Service after any revisions become
      effective, you agree to be bound by the revised terms. If you do not agree
      to the new terms, you are no longer authorized to use the Service.
    </p>
  </li>

  <li>
    <h2>WAIVER AND SEVERABILITY</h2>

    <p>
      No waiver by GETHINS of any term or condition set forth in Terms shall be
      deemed a further or continuing waiver of such term or condition or a
      waiver of any other term or condition, and any failure of Company to
      assert a right or provision under Terms shall not constitute a waiver of
      such right or provision.
    </p>
    <p>
      If any provision of Terms is held by a court or other tribunal of
      competent jurisdiction to be invalid, illegal or unenforceable for any
      reason, such provision shall be eliminated or limited to the minimum
      extent such that the remaining provisions of Terms will continue in full
      force and effect.
    </p>
  </li>

  <li>
    <h2>ACKNOWLEDGEMENT</h2>

    <p>
      BY USING THE SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
      THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
    </p>
  </li>

  <li>
    <h2>CONTACT US</h2>

    <p>
      You may contact us at<br />
      GETHINS LIMITED<br />
      Postal address: ONEWORLD PARKVIEW HOUSE, Floor 4, 75 Prodromou Strovolos,
      2063 Nicosia, Cyprus<br />
      Email address:
      <a [href]="'mailto:' + environment.policy.email">{{
        environment.policy.email
      }}</a
      >.
    </p>
  </li>
</ol>
