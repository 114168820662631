<p>Effective Date: [12/19/2023]</p>

<p>
  <a [href]="environment.policy.url" target="_blank">
    {{ environment.policy.url }}</a
  >
  which is available in the form of either a website or an application, uses
  cookies (the “<b>Website</b>”). By using our Website and agreeing to this
  cookies policy, you consent to our use of cookies in agreement with the
  provisions of this cookies policy.
</p>
<p>
  Using cookies helps us to provide you with a good experience when you browse
  our Website and also allows us to improve our site.
</p>
<p>
  This cookies policy describes how we use cookies and the choice you can make
  about whether we can out some types of cookies on your computer, tablet,
  mobile or other device.
</p>

<ol>
  <li>
    <h2>What are cookies?</h2>

    <p>
      Cookies are small text files that are placed on your computer, tablet,
      mobile or other device when you visit a website. It enables the website to
      remember your actions and preferences (such as IP address, language, font
      size and other display preferences) over a period of time, so you don’t
      have to keep re-entering them whenever you come back to the website or
      browse from one page to another.
    </p>
  </li>

  <li>
    <h2>Categories of cookies we use</h2>
    <p>We use the following cookies:</p>

    <ol>
      <li>
        <div>
          <b>Strictly necessary or essential cookies.</b> These are cookies that
          we are required to use in order to operate our Website. Without these
          cookies we may unable to provide some services that you might request.
          Other “essential” cookies keep our Website secure. Essential cookies
          are used to:

          <ol>
            <li>
              <p>
                enable a user to log into our Website and recognize such user
                when navigating the Website (“authentication cookies”);
              </p>
            </li>
            <li>
              <p>
                maintain online security and protect against malicious activity
                and/or violation of our Agreement/Terms and
                Conditions (“security cookies”)
              </p>
            </li>
            <li>
              <p>
                record your preference regarding our use of cookies on your
                device; and
              </p>
            </li>
            <li>
              <p>
                check if a user is allowed to access to a particular service or
                content.
              </p>
            </li>
          </ol>
          Essential cookies can be either persistent cookies or session cookies,
          depending on how they are implemented.
          <b>Persistent</b> are thee cookies which will be stored on your hard
          drive or browser until it expires or until you delete it. These
          cookies help us identify you as a unique visitor. <b>Session</b> are
          cookies are temporary cookies which will expire at the end of your
          session, when your web browser is closed. These cookies help our
          Website remember what you chose on the previous page, avoiding the
          need to re-enter information and improve your experience while using
          the Website.
        </div>
      </li>
      <li>
        <p>
          <b>Performance cookies (tracking Website performance):</b> These
          cookies are not used to identify you. We use this type of cookie to
          understand and analyze how visitors use and explore our Website and
          look for ways to improve it. We use these cookies to track our sites
          and plugins performance across the globe. Similarly, we may also use
          these analytics cookies to test new advertisements, pages, or features
          to gauge users’ reactions. The analytics cookie we use may be our own
          (i.e. first-party cookies) or third party cookies and include Google
          Analytics.
        </p>
      </li>
      <li>
        <div>
          <b>Functionality cookies</b> (giving you a better online experience):
          These cookies remember your preferences and tailor the Website to
          provide enhanced features. Without these cookies, we cannot remember
          your choices or personalize your online experience. We use this type
          of cookies to:

          <ol>
            <li>
              <p>
                2.3.1.make login faster by recognizing you if you are logged
                into the Website; and
              </p>
            </li>
            <li>
              <p>
                2.3.2.remember relevant information as you browse from page to
                page to save you re-entering the same information repeatedly
                (e.g. language, volume, HD mode).
              </p>
            </li>
          </ol>
        </div>
      </li>
      <li>
        <p>
          <b>Targeting cookies:</b> These cookies are usually third-party
          cookies from marketing partners used to deliver advertisements
          relevant to you and your interests. both on the Website and on other
          websites. These cookies can track your browsing history across the
          Website, the pages you have visited and the links you have followed.
          If you wish to prevent this type of cookie, you may do so, via your
          device’s browser security settings.
        </p>
      </li>
    </ol>
  </li>

  <li>
    <h2>Third parties</h2>
    <p>
      We do not share the information collected by the cookies with any third
      parties, other than described in Clause 5 below.
    </p>
  </li>

  <li>
    <h2>Manage your preferences</h2>
    <p>
      You can block cookies by activating the setting on your browser that
      allows you to refuse the setting of all or some cookies. However, if you
      use your browser settings to block all cookies (including essential
      cookies) you may not be able to access all or parts of our website.<br />For
      information on how to control or delete cookies, please visit
      <a href="" target="_blank">www.allaboutcookies.org</a>.
    </p>
  </li>

  <li>
    <h2>Google Analytics</h2>
    <p>
      We use Google Analytics, a web analytics service provided by Google, Inc.
      (hereinafter referred to as “Google”), using third-party cookies that
      enable Google to analyse the use of the Website. The information generated
      by the cookie about the use of this website is transferred to a Google
      servers. Note that we have activated Google’s IP anonymization service
      “anonymize IP” for our website which shortens a user’s IP address prior to
      the transfer so the usage is assessed with personal data being anonymized
      before being transferred.<br />Google will use this information on our
      behalf to evaluate the use of the Website, to compile reports for
      activities on the Website, and to provide other services to us relating to
      the Website.
    </p>
    <p>Google Analytics operates under the following terms:</p>
    <ul>
      <li>
        Terms of Service:
        <a href="http://www.google.com/analytics/terms/gb.html" target="_blank"
          >http://www.google.com/analytics/terms/gb.html</a
        >
      </li>
      <li>
        Privacy Statement:
        <a
          href=" https://policies.google.com/privacy?hl=en-US#intro"
          target="_blank"
        >
          https://policies.google.com/privacy?hl=en-US#intro</a
        >
      </li>
      <li>
        Privacy Policy:
        <a href="http://www.google.com/intl/en/policies/privacy" target="_blank"
          >http://www.google.com/intl/en/policies/privacy</a
        >
      </li>
    </ul>
    <p>
      The user can prevent Google from collecting and processing the data
      concerning the use of the Website (including the IP address) by installing
      a browser-plug-in which is available under
      <a href="https://tools.google.com/dlpage/gaoptout" target="_blank"
        >https://tools.google.com/dlpage/gaoptout</a
      >.
    </p>
  </li>

  <li>
    <h2>Yandex metrica</h2>
    <p>
      We use Yandex metrica  analytics service provided by YANDEX, LLC they
      operated under following terms:
    </p>
    <ul>
      <li>
        Terms of Use for Yandex.Metriсa Service:
        <a
          href="https://yandex.com/legal/metrica_eea_termsofuse/"
          target="_blank"
          >https://yandex.com/legal/metrica_eea_termsofuse/</a
        >
      </li>
      <li>
        the User Agreement for Yandex services:
        <a href="https://yandex.com/legal/rules" target="_blank"
          >https://yandex.com/legal/rules</a
        >
      </li>
      <li>
        Privacy Policy, available at:
        <a href="https://yandex.com/legal/confidential" target="_blank"
          >https://yandex.com/legal/confidential</a
        >
      </li>
    </ul>
  </li>

  <li>
    <h2>Control and deletion of cookies</h2>
    <p>
      You can control and/or delete cookies as you wish. You can delete all
      cookies that are already on your hard drive <b>and</b> you can set most
      browsers to prevent them from being placed. If you do this, however, you
      may have to manually adjust some preferences every time you visit our
      Website. If you do not wish cookies to track you, please use an internet
      browser which will allow you to disable them.
    </p>
    <ol>
      <li>
        On your first visit to the Website you will have seen a pop-up to inform
        you that cookies are being used when you proceed with using the Website.
        Although this pop-up will not usually appear on subsequent visits you
        may withdraw your cookie consent at any time by changing your browser or
        device settings.
      </li>
      <li>
        We would like to clarify that if you block or delete some or all your
        cookies, you might not be able to use all the features on our Website
        and as a result you may be unable to access certain parts on our Website
        and your experience on our Website might be affected.
      </li>
    </ol>
  </li>

  <li>
    <h2>Expiry</h2>
    <p>
      Except for strictly necessary cookies, all cookies will expire after 12
      months.
    </p>
  </li>

  <li>
    <h2>Our details</h2>
    <p>This Website is operated by</p>
    <p>
      GETHINS LIMITED Cyprus a.s. Att. of Data Privacy Officer<br />
      Postal address:ONEWORLD PARKVIEW HOUSE, Floor 4, 75 Prodromou Strovolos ,
      2063 Nicosia, Cyprus<br />
      Email address:
      <a [href]="'mailto:' + environment.policy.email">{{
        environment.policy.email
      }}</a>
    </p>
  </li>
</ol>
